<template>
    <div class="container-fluid">
      <div style="margin-bottom: 15px">
        <router-link to="/sender-control">
          <button class="btn btn-outline-secondary">
            <i class="bi bi-arrow-left-circle"></i> Voltar
          </button>
        </router-link>
      </div>
  
      <div class="card shadow mb-4 mt-1">
        <div class="card-header py-3 d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-start align-items-center gap-3">
            <div>                
              <h5 class="m-0 font-weight-bold text-primary">Tabela de Detalhe dos Senders</h5>
            </div>
          </div>
        </div>

        <ModalSenders :fields="this.sender_id" />

        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th scope="col">Numero / Email</th>
                        <th scope="col">País</th>
                        <th scope="col">Verificado</th>
                        <th scope="col">Integrador</th>
                        <th scope="col">Quality Rating</th>
                        <th scope="col">Alocado</th>
                        <th scope="col">Capacidade</th>
                        <th class="text-center" scope="col">Login</th>
                        <th class="text-center" scope="col">Senha</th>
                        <th scope="col">HSM Account</th>
                        <th scope="col">Senha</th>
                        <th scope="col">2-way account</th>
                        <th scope="col">Senha</th>
                        <th scope="col">Proprietário</th>
                        <th scope="col">Senha Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-if="loading !== true && loading_pagination !== true && list_senders.length"
                    v-for="sender in list_senders"
                    :key="sender.id"
                    >
                        <td>{{ sender.phone_number || 'Não cadastrado'}}</td>
                        <td>{{ sender.country || 'Não cadastrado'}}</td>
                        <td>
                          <i class="bi" 
                            :class="{
                            'bi-check-circle text-success': sender.verified === true,
                            'bi-x-circle text-danger': sender.verified === false
                            }">
                          </i>
                        </td>
                        <td>{{ sender.provider || 'Não cadastrado'}}</td>
                        <td>
                            <i class="bi" 
                                :class="{
                                'bi-check-circle text-success': sender.quality_rating === 'green',
                                'bi-exclamation-circle text-warning': sender.quality_rating === 'yellow',
                                'bi-x-circle text-danger': sender.quality_rating === 'Red'
                                }"></i>
                        </td>
                        <td class="clickable" data-bs-toggle="modal" data-bs-target="#modalDatailsSender" @click="">
                          {{ sender.count_course }}
                        </td>
                        <td>{{ sender.number_capacity || 'Não cadastrado'}}</td>
                        <td>{{ sender.number_login || 'Não cadastrado'}}</td>
                        <td>{{ sender.number_pass || 'Não cadastrado'}}</td>
                        <td>{{ sender.gupshup_credentials?.hsm_user_id || 'N/A' }}</td>
                        <td>{{ sender.gupshup_credentials?.hsm_password || 'N/A' }}</td>
                        <td>{{ sender.gupshup_credentials?.two_way_id || 'N/A' }}</td>
                        <td>{{ sender.gupshup_credentials?.two_way_password || 'N/A' }}</td>
                        <td>{{ sender.Owner || 'Não cadastrado'}}</td>
                        <td>{{ sender.email_password || 'Não cadastrado'}}</td>
                    </tr>
                    <tr v-else>
                    <td v-if="loading !== true && loading_pagination !== true" colspan="15" class="text-center">
                        Nenhum detalhe encontrado.
                    </td>
                    <td v-else colspan="15" class="text-center">
                        <Loader />
                    </td>
                    </tr>
                </tbody>
            </table>

  
            <div v-if="loading !== true && loading_pagination !== true && this.list_senders" class="pagination"> <!-- v-if para apenas quando o list_senders for carregado -->
              <button v-if="currentPage>1" class="btn-pagination" @click="prevPage" :disabled="currentPage === 1"><i class="bi bi-arrow-left-circle" style="
                font-size: 1.3rem;" ></i></button>
              <span>&nbsp; {{ currentPage }} de {{ totalPages }} &nbsp;</span>
              <button v-if="currentPage < totalPages" class="btn-pagination" @click="nextPage" :disabled="currentPage === totalPages"><i class="bi bi-arrow-right-circle" style="
                font-size: 1.3rem;"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
      
  <script>
  import { http } from "../../http";
  import Loader from "../shared/painel/Loader.vue";
  import ModalSenders from "../shared/ModalSenders.vue";

  export default {
    name: "DetailSender",
    components: {
        Loader,
        ModalSenders,
    },
  
    data() {
      return {
        loading: true,
        loading_pagination: false,
        token_course: '',
        list_senders: [],
        currentPage: 1,
        totalPages: 1,
        search_filter: '',
        sender_id: this.$route.params.id
      };
    },
  
    mounted() {
      this.list_sender(this.currentPage);
    },
  
    methods: {
      formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
        return new Date(dateString).toLocaleDateString('pt-BR', options);
      },
  
      list_sender(page) {
        const payload = {
          sender_id: this.$route.params.id
        };
  
        http.post("painel/atend_comercial/detail_sender/", payload)
        .then((res) => {
          this.list_senders = res.data.results;
        })
        .catch((error) => {
          console.error('Erro detail senders:', error);
        })
        .finally(() => {          
          setTimeout(() => {
              this.loading = false;
              this.loading_pagination = false;
          }, 1000);
        });      
      },    
  
      searchByNumberOrEmail() {
        if (this.search_filter == '') {
            this.search_filter = '';
        } else {
            this.search_filter = this.search_filter;
        }
  
        this.loading_pagination = true;
  
  
        this.currentPage = 1;
        this.list_sender(this.currentPage);
      },
  
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
          this.loading_pagination = true;
          this.list_sender(this.currentPage);
        }
      },
  
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.loading_pagination = true;
          this.list_sender(this.currentPage);
        }
      },
    },
  };
  </script>    
  
  <style>
    .btn-pagination {
      margin: none  ;
      border: none;
      background-color: transparent;
      color: black;
      font-weight: bold;
    }
  
    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
    .clickable {
      cursor:pointer;
    }
  </style>